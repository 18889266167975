<template>
  <div class="">
    <h4 class="fw-bold mb-4 d-flex page-title">
      <span class="mr-auto">
        {{ $trans('Orders') }}
      </span>
      <button @click="formFilter = !formFilter" class="btn btn-primary btn-sm mr-2">
        <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
             stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1 mr-1">
          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
        </svg>
        {{ $trans('Filter') }}
      </button>
      <button @click="displayFilter = !displayFilter" class="btn btn-primary btn-sm">
        {{ $trans('Display') }}
      </button>
    </h4>
    <div v-if="displayFilter" class="card mb-3">
      <div class="card-body">
        <div v-for="(key,k) in displayFields" class="form-check form-check-inline" :key="k">
          <input :id="'k-'+k" class="form-check-input" type="checkbox" v-model="displayFields[k]">
          <label class="form-check-label" :for="'k-'+k">
            <span>{{$trans(displayText[k])}}</span>
          </label>
        </div>
      </div>

    </div>
    <div v-if="formFilter" class="card mb-3">
      <div class="card-body">
        <div class="d-flex">
          <div class="form-group mr-3">
            <label>{{ $trans('Keyword') }}</label>
            <input v-model="filterQuery.keyword" :placeholder="'keyword here!'" type="text" class="form-control">
          </div>
          <div class="form-group mr-3">
            <label>{{ $trans('Service type') }}</label>
            <Select2 class="w-200" v-model="filterQuery.type" :options="seo_services"/>
          </div>
          <div class="form-group mr-3">
            <label>{{ $trans('Project') }}</label>
            <Select2 class="w-200" v-model="filterQuery.project_id" :options="projects"/>
          </div>
          <div class="form-group mr-3">
            <label>{{ $trans('User') }}</label>
            <Select2 class="w-200" v-model="filterQuery.user_id" :options="users"/>
          </div>
          <div class="form-group mr-3">
            <label>{{ $trans('Partner') }}</label>
            <Select2 class="w-200" v-model="filterQuery.partner_id" :options="partners"/>
          </div>
          <div class="form-group mr-3">
            <label>{{ $trans('Status') }}</label>
            <select class="form-control" v-model="filterQuery.status">
              <option value="all">{{ $trans('All') }}</option>
              <option v-for="(s,k) in order_status" :key="k" :value="k">{{ $trans(s) }}</option>
            </select>
          </div>
        </div>
        <div class="d-flex">
          <div class="form-group mr-3">
            <label class="d-block">{{ $trans('From date') }}</label>
            <vuejs-datepicker input-class="form-control" class="w-200" v-model="filterQuery.from"></vuejs-datepicker>
          </div>
          <div class="form-group  mr-3">
            <label class="d-block">{{ $trans('To date') }}</label>
            <vuejs-datepicker input-class="form-control" class="w-200" v-model="filterQuery.to"></vuejs-datepicker>
          </div>
        </div>
        <div class="filter-actions d-flex">
          <button @click="reset_filter" class="btn btn-outline-danger btn-sm mr-3">{{ $trans('Reset') }}</button>
          <button @click="index" class="btn btn-outline-primary btn-sm mr-3">
            <div v-if="process_loading" class="spinner-border spinner-border-sm text-secondary"></div>
            {{ $trans('Apply') }}
          </button>
          <download-csv v-if="json_data && json_data.length" :labels="labels" :data="json_data"
                        :name="export_file_name">
            <button class="btn btn-sm btn-primary">{{ $trans('Download') }}</button>
          </download-csv>
          <button v-else @click="export_data" class="btn btn-primary btn-sm mr-3">
            <div v-if="process_export_loading" class="spinner-border spinner-border-sm text-secondary"></div>
            {{ $trans('Export') }}
          </button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div v-if="requests && requests.data" class="table-responsive">
          <div class="list-info mb-3">
            <small>{{ $trans('Results') }}: <strong>{{ requests.data.length }}</strong> {{ $trans('of') }}
              <strong>{{ requests.total }}</strong></small>
          </div>
          <table class="table mb-3">
            <thead>
            <tr>
              <th v-if="displayFields.ref_number">{{ $trans('Ref Number') }}</th>
              <th v-if="displayFields.date">{{ $trans('Date') }}</th>
              <th v-if="displayFields.petitioner">{{ $trans('Petitioner') }}</th>
              <th v-if="displayFields.type">{{ $trans('Type') }}</th>
              <th v-if="displayFields.Project">{{ $trans('Project') }}</th>
              <th v-if="displayFields.Group">{{ $trans('Group') }}</th>
              <th v-if="displayFields.Partner">{{ $trans('Partner') }}</th>
              <th v-if="displayFields.Domain">{{ $trans('Domain/Package/...') }}</th>
              <th v-if="displayFields.File">{{ $trans('File link/text/...') }}</th>
              <th v-if="displayFields.VND">{{ $trans('VND') }}</th>
              <th v-if="displayFields.Report_Link">{{ $trans('Report Link') }}</th>
              <th v-if="displayFields.Bank_Name" >{{ $trans('Bank Name') }}</th>
              <th v-if="displayFields.Bank_Account_Name">{{ $trans('Bank Account Name') }}</th>
              <th v-if="displayFields.Bank_Account_Number">{{ $trans('Bank Account Number') }}</th>
              <th v-if="displayFields.VND" class="text-right">{{ $trans('VND') }}</th>
              <th v-if="displayFields.USD" class="text-right">{{ $trans('USD') }}</th>
              <th v-if="displayFields.Discount">{{ $trans('Discount') }}</th>
              <th v-if="displayFields.Total_VND" class="text-right">{{ $trans('Total VND') }}</th>
              <th v-if="displayFields.Total_USD" class="text-right">{{ $trans('Total USD') }}</th>
              <th v-if="displayFields.Note">{{ $trans('Note') }}</th>
              <th>{{ $trans('Status') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(request,index) in requests.data" v-bind:key="index">
              <td v-if="displayFields.ref_number" class="link" @click="detail(request)">{{ request.ref_number }}</td>
              <td v-if="displayFields.date">{{ request.date }}</td>
              <td v-if="displayFields.petitioner">
                <slot v-if="request.user">{{ request.user.username }}</slot>
              </td>
              <td v-if="displayFields.type" class="nowrap">{{ $trans(request.type) }}</td>
              <td v-if="displayFields.Project">
                <slot v-if="request.project">{{ request.project.name }}</slot>
              </td>
              <td v-if="displayFields.Group">
                <slot v-if="request.project">{{ request.project.group }}</slot>
              </td>
              <td v-if="displayFields.Partner">
                <slot v-if="request.partner">{{ request.partner.name }}</slot>
              </td>
              <td v-if="displayFields.Domain">
              <span v-for="(item,k) in request.detail" :key="k" class="d-block sort-text">
                <span v-html="$short_link(item.title)" v-if="k<=2"></span>
              </span>
              </td>
              <td v-if="displayFields.File">
                <span v-for="(item,k) in request.detail" :key="k" class="d-block sort-text">
                  <span v-html="$short_link(item.text)" v-if="k<=2"></span>
              </span>
              </td>
              <td v-if="displayFields.VND">
                <span v-for="(item,k) in request.detail" :key="k" class="d-block">
                <slot v-if="k<=2">
                  <vue-numeric
                      currency-symbol-position="suffix"
                      currency=""
                      v-model="item.price"
                      v-bind:read-only="true"
                      :minus="true"
                      v-bind:precision="0">
                </vue-numeric>
                </slot>
              </span>
              </td>
              <td v-if="displayFields.Report_Link">
                <span v-for="(item,k) in request.detail" :key="k" class="d-block sort-text">
                  <span v-html="$short_link(item.report_link)" v-if="k<=2"></span>
                </span>
              </td>
              <td v-if="displayFields.Bank_Name">
                {{ request.payment_info.bank_name }}
              </td>
              <td v-if="displayFields.Bank_Account_Name">
                {{ request.payment_info.bank_account_name }}
              </td>
              <td v-if="displayFields.Bank_Account_Number">
                {{ request.payment_info.bank_account_number }}
              </td>
              <td v-if="displayFields.VND"  class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency=""
                    v-model="request.amount"
                    v-bind:read-only="true"
                    :minus="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td v-if="displayFields.USD" class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency=""
                    v-model="request.usd"
                    v-bind:read-only="true"
                    :minus="true"
                    v-bind:precision="2">
                </vue-numeric>
              </td>
              <td v-if="displayFields.Discount">
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency="%"
                    v-model="request.discount"
                    v-bind:read-only="true"
                    v-bind:precision="2">
                </vue-numeric>
              </td>
              <td  v-if="displayFields.Total_VND" class="text-success text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency=""
                    v-model="request.total"
                    :minus="true"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td v-if="displayFields.Total_USD" class="text-success text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency=""
                    v-model="request.total_usd"
                    :minus="true"
                    v-bind:read-only="true"
                    v-bind:precision="2">
                </vue-numeric>
              </td>
              <td v-if="displayFields.Note">{{ request.note }}</td>
              <td>
                <request-status :status="request.status"></request-status>
              </td>
            </tr>
            </tbody>
          </table>
          <paginate v-if="requests && requests.data && requests.last_page"
                    :page-count="requests.last_page"
                    :click-handler="paginate"
                    :prev-text="$trans('Previous')"
                    :next-text="$trans('Next')"
                    :page-class="'page-item'"
                    :prev-class="'page-item'"
                    :next-class="'page-item'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
    <modal v-if="detailForm" v-bind:width="'100%'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Request Detail') }}
          <button @click="detailForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <div class="row">
          <div class="col-sm-7">
            <table class="table table-striped">
              <tbody>
              <tr>
                <th class="nowrap">{{ $trans('Ref Number') }}</th>
                <td colspan="2">{{ request.ref_number }}</td>
              </tr>
              <tr>
                <th class="nowrap">{{ $trans('Petitioner') }}</th>
                <td colspan="2">
                  <slot v-if="request.user">{{ request.user.username }}</slot>
                </td>
              </tr>
              <tr>
                <th class="nowrap">{{ $trans('Type') }}</th>
                <td colspan="2">{{ $trans(request.type) }}</td>
              </tr>
              <tr>
                <th class="nowrap">{{ $trans('Project') }}</th>
                <td colspan="2">
                  <slot v-if="request.project">{{ request.project.name }}</slot>
                </td>

              </tr>
              <tr>
                <th class="nowrap">{{ $trans('Partner') }}</th>
                <td colspan="2">
                  <slot v-if="request.partner">{{ request.partner.name }}</slot>
                </td>
              </tr>
              <tr>
                <th class="nowrap">{{ $trans('Amount') }}</th>
                <td colspan="2">
                  <vue-numeric
                      currency-symbol-position="suffix"
                      currency=""
                      v-model="request.amount"
                      v-bind:read-only="true"
                      :minus="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </td>
              </tr>
              <tr>
                <th class="nowrap">{{ $trans('Discount') }}</th>
                <td>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      currency="%"
                      v-model="request.discount"
                      v-bind:read-only="true"
                      v-bind:precision="2">
                  </vue-numeric>
                </td>
                <td class="text-right">
                  <a v-if="$can('edit-price-orders') && request.status!=1 && request.status!=-1 "
                     @click="editPriceForm = true" href="javascript:void(0)">
                    <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                         stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>
                  </a>
                </td>
              </tr>
              <tr>
                <th class="nowrap">{{ $trans('Total amount') }}</th>
                <td colspan="2">
                  <vue-numeric
                      currency-symbol-position="suffix"
                      currency=""
                      v-model="request.total"
                      v-bind:read-only="true"
                      :minus="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </td>
              </tr>
              <tr>
                <th class="nowrap">{{ $trans('Bank Name') }}</th>
                <td>
                  {{ request.payment_info.bank_name }}
                </td>
                <td class="text-right">
                  <a v-if="$can('edit-price-orders') && request.status!=1 && request.status!=-1 "
                     @click="editPaymentForm = true" href="javascript:void(0)">
                    <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
                         stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>
                  </a>
                </td>
              </tr>
              <tr>
                <th class="nowrap">{{ $trans('Bank Account Name') }}</th>
                <td colspan="2">
                  {{ request.payment_info.bank_account_name }}
                </td>
              </tr>
              <tr>
                <th class="nowrap">{{ $trans('Bank Account Number') }}</th>
                <td colspan="2">
                  {{ request.payment_info.bank_account_number }}
                </td>
              </tr>
              <tr>
                <th class="nowrap">{{ $trans('Status') }}</th>
                <td colspan="2">
                  <request-status :status="request.status"></request-status>
                </td>

              </tr>
              <tr>
                <th class="nowrap">{{ $trans('Date') }}</th>
                <td colspan="2">{{ request.created_at }}</td>
              </tr>
              <tr>
                <th class="nowrap">{{ $trans('Note') }}</th>
                <td class="pre-line" colspan="2">{{ request.note }}</td>
              </tr>
              </tbody>
            </table>
            <h5 class="d-flex text-uppercase">
              <span class="mr-2">{{ $trans('Detail') }}</span>
              <a v-if="$can('edit-detail-orders')" @click="editDetailForm = true" href="javascript:void(0)">
                <svg viewBox="0 0 24 24" width="17" height="17" stroke="currentColor" stroke-width="2" fill="none"
                     stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                </svg>
              </a>
            </h5>
            <table class="table">
              <thead>
              <tr>
                <th>{{ $detail_title_text(request.type) }}</th>
                <th>{{ $detail_text_text(request.type) }}</th>
                <th>{{ $trans('VND') }}</th>
                <th>{{ $trans('USD') }}</th>
                <th>{{ $trans('Report Link') }}</th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="(item,k) in request.detail" :key="k">
                <td v-html="$short_link(item.title)"></td>
                <td v-html="$short_link(item.text)"></td>
                <td>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      currency=""
                      v-model="item.price"
                      v-bind:read-only="true"
                      :minus="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </td>
                <td>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      currency=""
                      v-model="item.usd"
                      v-bind:read-only="true"
                      :minus="true"
                      v-bind:precision="2">
                  </vue-numeric>
                </td>
                <td v-html="$short_link(item.report_link)"></td>
              </tr>
              </tbody>
            </table>
            <div v-if="$can('confirm-orders')" class="form-actions">
              <div v-if="request && request.status == 0" class="d-flex">
                <button @click="change_status_request(request,'confirm')" class="btn btn-primary mr-3">
                  <div v-if="process_change_status" class="spinner-border spinner-border-sm text-secondary"></div>
                  {{ $trans('Confirm') }}
                </button>
                <button @click="reject_request(request)" class="btn btn-danger mr-3">
                  <div v-if="process_reject" class="spinner-border spinner-border-sm text-secondary"></div>
                  {{ $trans('Reject') }}
                </button>
              </div>
              <div v-if="request && (request.status == 2 || request.status == -2)" class="">
                <div class="form-group">
                  <div class="form-check">
                    <input id="login-locked" class="form-check-input" type="checkbox" v-model="request.request_payment"
                           true-value="1" false-value="0">
                    <label class="form-check-label" for="login-locked">
                      <span>{{ $trans('Checked & Request payment') }}</span>
                    </label>
                  </div>
                </div>
                <button @click="change_status_request(request,'done')" class="btn btn-primary mr-3">
                  <div v-if="process_change_status" class="spinner-border spinner-border-sm text-secondary"></div>
                  {{ $trans('Done & Request Check') }}
                </button>
                <slot v-if="request.status == 2">
                  <button @click="reject_request(request)" class="btn btn-danger mr-3">
                    <div v-if="process_reject" class="spinner-border spinner-border-sm text-secondary"></div>
                    {{ $trans('Reject') }}
                  </button>
                </slot>
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <div v-if="logs" class="card shadow-0 border" style="background-color: #f0f2f5;">
              <div class="card-body p-4">
                <div v-for="(log,k) in logs" :key="k" class="card mb-2">
                  <div class="card-body p-2">
                    <div class="mb-1">
                      {{ log.title }}
                      <slot v-if="log.image">
                        <br>
                        <a target="_blank" :href="url+'/uploads/'+log.image">
                          <img :src="url+'/uploads/'+log.image" alt="">
                        </a>
                      </slot>
                    </div>
                    <div class="d-flex justify-content-between">
                      <div class="d-flex flex-row align-items-center">
                        <img src="../assets/img/avatars/6.png" alt="" width="25" height="25"/>
                        <p class="small mb-0 ms-2">{{ log.username }}</p>
                      </div>
                      <div class="d-flex flex-row align-items-center">
                        <p class="small text-muted mb-0">{{ log.time }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </slot>
    </modal>
    <modal v-if="editPriceForm" v-bind:width="'500px'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Edit Price') }}
          <button @click="editPriceForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <div class="form-group">
          <label>{{ $trans('Discount') }} (*)</label>
          <vue-numeric class="form-control"
                       currency-symbol-position="suffix"
                       currency="%"
                       v-model="request.discount"
                       v-bind:read-only="false"
                       v-bind:precision="2">
          </vue-numeric>
          <slot v-if="errors && errors['discount']">
            <div class="text-danger" v-for="error in errors['discount']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <button @click="update" class="btn btn-primary">
          <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
          {{ $trans('Update') }}
        </button>
      </slot>
    </modal>
    <modal v-if="editDetailForm" v-bind:width="'1000px'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Edit Detail') }}
          <button @click="editDetailForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <div class="form-group">
          <label>{{ $trans('Detail') }} (*)</label>
          <table class="table-striped table">
            <thead>
            <tr>
              <th>{{ $detail_title_text(request.type) }}</th>
              <th>{{ $detail_text_text(request.type) }}</th>
              <th>{{ $trans('VND') }}</th>
              <th>{{ $trans('USD') }}</th>
              <th>{{ $trans('Report Link') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,k) in detail_table" :key="k">
              <td>
                <input v-model="detail_table[k]['title']" class="form-control" type="text">
              </td>
              <td>
                <input v-model="detail_table[k]['text']" class="form-control" type="text">
              </td>
              <td>
                <vue-numeric class="form-control"
                             currency-symbol-position="suffix"
                             currency=""
                             v-model="detail_table[k]['price']"
                             v-bind:read-only="false"
                             :minus="true"
                             v-bind:precision="0">
                </vue-numeric>
              </td>
              <td>
                <vue-numeric class="form-control"
                             currency-symbol-position="suffix"
                             currency=""
                             v-model="detail_table[k]['usd']"
                             v-bind:read-only="false"
                             :minus="true"
                             v-bind:precision="2">
                </vue-numeric>
              </td>
              <td>
                <input v-model="detail_table[k]['report_link']" class="form-control" type="text">
              </td>
              <td class="text-right">
                <a @click="removeDetail(k)" href="javascript:void(0)">
                  {{ $trans('Remove') }}
                </a>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td colspan="5"></td>
              <td class="text-right">
                <a @click="addDetail()" href="javascript:void(0)">
                  {{ $trans('Add') }}
                </a>
              </td>
            </tr>
            </tfoot>
          </table>
          <slot v-if="errors && errors['detail']">
            <div class="text-danger" v-for="error in errors['detail']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <button @click="update_detail" class="btn btn-primary">
          <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
          {{ $trans('Update') }}
        </button>
      </slot>
    </modal>
    <modal v-if="editPaymentForm" v-bind:width="'500px'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Edit Payment Infomation') }}
          <button @click="editPaymentForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <div class="form-group">
          <label>{{$trans('Bank Name')}} (*)</label>
          <input v-model="request.payment_info.bank_name" type="text" class="form-control">
          <slot v-if="errors && errors['bank_name']">
            <div class="text-danger" v-for="error in errors['bank_name']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Bank Account Name')}} (*)</label>
          <input v-model="request.payment_info.bank_account_name" type="text" class="form-control">
          <slot v-if="errors && errors['bank_account_name']">
            <div class="text-danger" v-for="error in errors['bank_account_name']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Bank Account Number')}} (*)</label>
          <input v-model="request.payment_info.bank_account_number" type="text" class="form-control">
          <slot v-if="errors && errors['bank_account_number']">
            <div class="text-danger" v-for="error in errors['bank_account_number']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <button @click="update_bank_info" class="btn btn-primary">
          <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
          {{ $trans('Update') }}
        </button>
      </slot>
    </modal>
  </div>
</template>
<script>
import axios from "axios";
import Pusher from "pusher-js";
import moment from "moment/moment";

export default {
  name: "Orders",
  data: function () {
    return {
      detail_table: [
        {
          title: '',
          text: '',
          price: 0,
          report_link: ''
        }
      ],
      displayFilter:false,
      editDetailForm: false,
      editPaymentForm: false,
      process: false,
      process_change_status: false,
      process_export_loading: false,
      formFilter: false,
      process_reject: false,
      editPriceForm: false,
      filterQuery: {
        page: 1,
        from: null,
        to: new Date(),
        status: 'all',
        partner_id: '',
        project_id: '',
        keyword: ''
      },
      requests: null,
      process_loading: false,
      detailForm: false,
      partners_res: null,
      projects_res: null,
      request: null,
      alert: null,
      errors: {},
      export_requests: null,
      response_users: null,
      displayFields:{
        ref_number: true,
        date:true,
        petitioner:true,
        type:true,
        Project:true,
        Group:true,
        Partner:true,
        Domain:true,
        File:true,
        VND:true,
        Report_Link:true,
        Bank_Name:true,
        Bank_Account_Name:true,
        Bank_Account_Number:true,
        USD:true,
        Discount:true,
        Total_VND:true,
        Total_USD:true,
        Note:true,
      },
      displayText:{
        ref_number: 'Ref Number',
        date:'Date',
        petitioner:'Petitioner',
        type:'Type',
        Project:'Project',
        Group:'Group',
        Partner:'Partner',
        Domain:'Domain/Package/...',
        File:'File link/text/...',
        VND:'VND',
        Report_Link:'Report Link',
        Bank_Name:'Bank Name',
        Bank_Account_Name:'Bank Account Name',
        Bank_Account_Number:'Bank Account Number',
        USD:'USD',
        Discount:'Discount',
        Total_VND:'Total VND',
        Total_USD:'Total USD',
        Note:'Note',
      }
    }
  },
  mounted() {

    var today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    if (month == 1) {
      month = 12;
      year = year - 1;
    }else {
      month = month -1
    }
    this.filterQuery.from = new Date(year, month, 1);
  },
  methods: {
    addDetail: function () {
      let item = {
        title: '',
        text: '',
        price: 0,
        usd: 0,
        report_link: ''
      }

      let items = this.detail_table
      items.push(item)
      this.detail_table = items
    },
    removeDetail: function (index) {
      let items = this.detail_table
      items.splice(index, 1);

      this.detail_table = items
    },
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    index: function () {
      this.process_loading = true
      axios({url: this.$root.$data.api_url + '/api/orders', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.requests = resp.data;
            this.process_loading = false
            this.export_requests = null
          })
          .catch(err => {
            console.log(err)
          })
    },
    reset_filter: function () {
      var today = new Date();
      let month = today.getMonth();
      let year = today.getFullYear();
      if (month == 1) {
        month = 12;
        year = year - 1;
      }else {
        month = month -1
      }
      this.filterQuery = {
        from: new Date(year, month, 1),
        to: new Date(),
        page: 1,
        status: 'all',
        partner_id: '',
        project_id: '',
        keyword: '',
      }
      this.index()
    },
    detail: function (request) {
      axios({url: this.$root.$data.api_url + '/api/orders/' + request._id, method: 'GET'})
          .then(resp => {
            this.request = resp.data.data;
            this.detailForm = true;
            this.detail_table = resp.data.data.detail;
          })
          .catch(err => {
            console.log(err)
          })
    },
    get_partners: function () {
      axios({url: this.$root.$data.api_url + '/api/order/partners', params: {}, method: 'GET'})
          .then(resp => {
            this.partners_res = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    get_projects: function () {
      axios({url: this.$root.$data.api_url + '/api/order/projects', params: {}, method: 'GET'})
          .then(resp => {
            this.projects_res = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    reject_request: function () {
      if (this.process_reject == false) {
        this.process_reject = true;
        this.errors = {}
        this.alert = null
        let data = {
          amount: this.request.amount,
          _id: this.request._id,
          discount: this.request.discount,

        }
        data.detail = this.detail_table
        axios({url: this.$root.$data.api_url + '/api/orders/reject/' + data._id, data: data, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.editPriceForm = false;
                this.index();
                this.detail(this.request)
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process_reject = false;
            })
            .catch(err => {
              console.log(err)
              this.process_reject = false;
            })
      }
    },
    change_status_request: function (request, action) {
      if (this.process_change_status == false) {
        this.process_change_status = true;
        this.errors = {}
        this.alert = null
        let data = {
          _id: request._id,
          action: action,
          report_link: request.report_link,
          request_payment: request.request_payment,
        }
        axios({url: this.$root.$data.api_url + '/api/orders/confirm/' + data._id, data: data, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.index();
                this.detail(this.request)
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process_change_status = false;
            })
            .catch(err => {
              console.log(err)
              this.process_change_status = false;
            })
      }
    },
    update: function () {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = {
          amount: this.request.amount,
          _id: this.request._id,
          discount: this.request.discount,

        }
        data.detail = this.detail_table
        axios({url: this.$root.$data.api_url + '/api/orders/update-discount/' + data._id, data: data, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.editPriceForm = false;
                this.index();
                this.detail(this.request)
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    update_bank_info:function () {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = {
          bank_name: this.request.payment_info.bank_name,
          bank_account_name: this.request.payment_info.bank_account_name,
          bank_account_number: this.request.payment_info.bank_account_number,
          _id: this.request._id

        }
        data.detail = this.detail_table
        axios({url: this.$root.$data.api_url + '/api/orders/update_bank_info/' + data._id, data: data, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.editPaymentForm = false;
                this.index();
                this.detail(this.request)
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    export_data: function () {
      this.process_export_loading = true
      axios({url: this.$root.$data.api_url + '/api/orders-export', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.export_requests = resp.data;
            this.process_export_loading = false
          })
          .catch(err => {
            console.log(err)
          })
    },
    update_detail: function () {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = {
          _id: this.request._id,
          detail: this.detail_table,

        }
        data.detail = this.detail_table
        axios({url: this.$root.$data.api_url + '/api/orders/update-detail/' + data._id, data: data, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.editDetailForm = false;
                this.index();
                this.detail(this.request)
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    get_users: function () {
      axios({url: this.$root.$data.api_url + '/api/all_user', params: {roles: []}, method: 'GET'})
          .then(resp => {
            this.response_users = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
  },
  created() {
    this.index()
    this.get_partners()
    this.get_projects()
    this.get_users()
    // Real time
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY,
        {
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          encrypted: true
        }
    );
    const channel = pusher.subscribe('order-channel');
    channel.bind('order-event', (e) => {
      if (this.requests && this.requests.data) {
        if (e.action == 'created') {
          this.requests.data.unshift(e.order);
        } else {
          var arr = this.requests.data;
          var tmp_arr = [];
          var current_id = e.order._id;
          arr.forEach(function (el, index) {
            if (current_id == el._id) {
              tmp_arr[index] = e.order;
            } else {
              tmp_arr[index] = el;
            }
          });
          this.requests.data = tmp_arr;
        }
      }
    });
  },
  computed: {
    seo_services: function () {
      return this.$root.$data.global_settings.seo_services
    },
    order_status: function () {
      return this.$root.$data.global_settings.order_status
    },
    projects: function () {
      if (!this.projects_res) {
        return [];
      }
      let projects = [];
      let projects_res = this.projects_res;
      projects_res.forEach((el) => {
        let item = {
          id: el._id,
          text: el.name
        }
        projects.push(item)
      })

      return projects;
    },
    partners: function () {
      if (!this.partners_res) {
        return [];
      }
      let partners = [];
      let partners_res = this.partners_res;
      partners_res.forEach((el) => {
        let item = {
          id: el._id,
          text: el.name
        }
        partners.push(item)
      })

      return partners;
    },
    user: function () {
      return this.$root.user
    },
    url: function () {
      return this.$root.$data.api_url
    },
    logs: function () {
      if (!this.request) {
        return null
      }
      if (!this.request.logs) {
        return null
      }
      let logs = this.request.logs
      return logs.reverse()
    },
    json_data: function () {
      if (!this.export_requests) {
        return []
      }
      let json_data = []
      let export_requests = this.export_requests
      export_requests.forEach((el) => {
        let content = ''
        let detail = ''
        el.detail.forEach((row) => {
          content = content + row.title + "\n"
          detail = detail + row.text + "\n"
        })
        let item = {
          ref_number: el.ref_number,
          date: el.date,
          type: el.type,
          project: el.project.name,
          group: el.project.group,
          partner: el.partner.name,
          content: content,
          detail: detail,
          report_link: el.report_link,
          bank_name: el.payment_info.bank_name,
          bank_account_name: el.payment_info.bank_account_name,
          bank_account_number: el.payment_info.bank_account_number,
          amount: el.amount,
          discount: el.discount,
          total: el.total,
          note: el.note,
          status: this.order_status[el.status]
        }
        json_data.push(item)
      })
      return json_data
    },
    export_file_name: function () {
      let from = ''
      let to = ''
      from = moment(this.filterQuery.from).format('YYYY-MM-DD')
      to = moment(this.filterQuery.to).format('YYYY-MM-DD')
      return 'orders-' + from + '-' + to + '.csv';
    },
    labels: function () {
      return {
        ref_number: 'Ref Number',
        date: 'Date'.toUpperCase(),
        type: 'Type'.toUpperCase(),
        project: 'Project'.toUpperCase(),
        group: 'Group'.toUpperCase(),
        partner: 'Partner'.toUpperCase(),
        content: 'Content'.toUpperCase(),
        detail: 'Detail'.toUpperCase(),
        report_link: 'Report Link'.toUpperCase(),
        bank_name: 'Bank Name'.toUpperCase(),
        bank_account_name: 'Bank Account Name'.toUpperCase(),
        bank_account_number: 'Bank Account Number'.toUpperCase(),
        amount: 'Amount'.toUpperCase(),
        discount: 'Discount'.toUpperCase(),
        total: 'Total Amount'.toUpperCase(),
        note: 'Note'.toUpperCase(),
        status: 'Status'.toUpperCase()
      }
    },
    users: function () {
      if (!this.response_users) {
        return [];
      }
      let users = [];
      let response_users = this.response_users;
      response_users.forEach((el) => {
        let item = {
          id: el._id,
          text: el.username
        }
        users.push(item)
      })

      return users;
    },

  }
}
</script>

<style scoped>

</style>